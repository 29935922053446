import { calander } from '../constant';
const { 
    GFReq,
    GFErr,
    GFSuc,
    CMReq,
    CMErr,
    CMSuc,
    CWReq,
    CWErr,
    CWSuc,
    CDReq,
    CDErr,
    CDSuc,
    CAReq,
    CAErr,
    CASuc
     } = calander
    

const iS = {
    isSuc: false, isErr: false, isL: false, data: []
};
const iSM = {
    isSuc: false, isErr: false, isL: false, data: []
};

const iSW = {
    isSuc: false, isErr: false, isL: false, data: []
};

const iSD = {
    isSuc: false, isErr: false, isL: false, data: []
};

const iSA = {
    isSuc: false, isErr: false, isL: false, data: []
};



export const CalenderUsers = (state = iS, action) => {


    switch (action.type) {
        case GFSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case GFReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case GFErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}

export const CalenderMonths = (state = iSM, action) => {


    switch (action.type) {
        case CMSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case CMReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case CMErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}

export const CalenderWeeks = (state = iSW, action) => {


    switch (action.type) {
        case CWSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case CWReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case CWErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}

export const CalenderDays = (state = iSD, action) => {


    switch (action.type) {
        case CDSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case CDReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case CDErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}

export const CalenderAgends = (state = iSA, action) => {


    switch (action.type) {
        case CASuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case CAReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case CAErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}