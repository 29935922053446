import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
const Registration = lazy(() => import('../components/auth/Registration'))
const Login = lazy(() => import('../components/auth/Login'))
const Message = lazy(() => import('../components/auth/Message'))
const EmailVerification = lazy(() => import('../components/auth/EmailVerification'))
const ForgetPassword = lazy(() => import('../components/auth/ForgetPassword'))
const ResetPassword = lazy(() => import('../components/auth/ResetPassword'))
const Home = lazy(() => import('../components/auth/Home'))
const ContactUs = lazy(() => import('../components/auth/ContactUs'))
const ConnectPage = lazy(() => import('../components/auth/ConnectPage'))
const FollowedStreams = lazy(() => import('../components/auth/FollowedStreams'))
const UserSettings = lazy(() => import('../components/auth/UserSettings'))
const TwitchSettings = lazy(() => import('../components/auth/TwitchSettings'))
const NotificationSettings = lazy(() => import('../components/auth/NotificationsSettings'))

const users = () => <Routes>
    <Route exact path="/registration" element={<Registration />} />
    <Route exact path="/login" element={<Login />} />
    <Route exact path="/message/:email" element={<Message />} />
    <Route exact path="/email_verification/:token/:user_id" element={<EmailVerification />} />
    <Route exact path="/forget_password" element={<ForgetPassword />} />
    <Route exact path="/reset_password/:token/:user_id" element={<ResetPassword />} />
    <Route exact path="/" element={<Home />} />
    <Route exact path="/connect" element={<ConnectPage />} />
    <Route exact path="/contact_us" element={<ContactUs />} />
    <Route exact path="/user_followed" element={<FollowedStreams />} />
    <Route exact path="/user_settings" element={<UserSettings />} />
    <Route exact path="/twitch_settings" element={<TwitchSettings />} />
    <Route exact path="/notification_settings" element={<NotificationSettings />} />


</Routes>

export default users
