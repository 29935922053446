import { t_authConstants, tusers_authConstants } from '../constant';

const { TAReq, TAErr, TASuc, TAClr, TAAddAtt, TADelAtt, TAUptAtt, TFAddAtt, TFASuc } = t_authConstants
const { TUReq, TUErr, TUSuc, TUClr, TUAddAtt, TUDelAtt, TUUptAtt } = tusers_authConstants

const iS = {
    isSuc: false, isErr: false, isL: false, list: [], data: []
};

export const TAReducer = (state = iS, action) => {

    let listTemp = state.list;
    let dataTemp = state.data;
    switch (action.type) {
        case TASuc: return { ...state, isErr: false, isSuc: true, isL: false, list: action.payload };
        case TFASuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };

        case TAReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TAErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TAClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TADelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, list: listTemp, isL: false
            };

        case TAUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    let a = i.toLowerCase()

                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, list: listTemp, isL: false
            };


        case TAAddAtt:
            if (dataTemp && dataTemp.length > 0)
                dataTemp = dataTemp.concat([action.payload]);
            else if (dataTemp) {
                dataTemp = [].concat([action.payload]);
            }

            return {
                ...state, data: dataTemp, isL: false
            };
        case TFAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat(action.payload);
            else if (listTemp) {
                listTemp = listTemp.concat(action.payload);
            }

            return {
                ...state, list: listTemp, isL: false
            };
        // case TAAddAtt:
        //     if (listTemp && listTemp.length > 0)
        //         listTemp = listTemp.concat([action.payload]);
        //     else if (listTemp) {
        //         listTemp = [].concat([action.payload]);
        //     }

        //     return {
        //         ...state, list: listTemp, isL: false
        //     };
        default: return state;
    }
}


export const TUserReducer = (state = iS, action) => {

    let listTemp = state.data;



    switch (action.type) {
        case TUSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case TUReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TUErr: return { ...state, isErr: true, isSuc: false, isL: false };
        case TUClr: return { ...state, isErr: false, isSuc: false, isL: false };
        case TUDelAtt:
            if (listTemp && listTemp.length > 0) {
                listTemp = listTemp.filter(i => Number(i._id) !== Number(action.payload._id));
            }

            return {
                ...state, data: listTemp, isL: false
            };

        case TUUptAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.map(i => {
                    if (Number(i._id) === Number(action.payload._id)) i = action.payload;
                    return i;
                });

            return {
                ...state, data: listTemp, isL: false
            };



        case TUAddAtt:
            if (listTemp && listTemp.length > 0)
                listTemp = listTemp.concat(action.payload);
            else if (listTemp) {
                listTemp = listTemp.concat(action.payload);
            }

            return {
                ...state, data: listTemp, isL: false
            };
        // case TAAddAtt:
        //     if (listTemp && listTemp.length > 0)
        //         listTemp = listTemp.concat([action.payload]);
        //     else if (listTemp) {
        //         listTemp = [].concat([action.payload]);
        //     }

        //     return {
        //         ...state, list: listTemp, isL: false
        //     };
        default: return state;
    }
}
