import { twitch_settings, notification_settings } from '../constant';

const { TSReq, TSErr, TSSuc, } = twitch_settings
const { NSReq, NSErr, NSSuc, } = notification_settings

const iS = {
    isSuc: false, isErr: false, isL: false, data: []
};
const iSN = {
    isSuc: false, isErr: false, isL: false, data: []
};




export const TSettingsReducer = (state = iS, action) => {


    switch (action.type) {
        case TSSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case TSReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case TSErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}

export const NSettingsReducer = (state = iSN, action) => {


    switch (action.type) {
        case NSSuc: return { ...state, isErr: false, isSuc: true, isL: false, data: action.payload };
        case NSReq: return { ...state, isErr: false, isSuc: false, isL: true };
        case NSErr: return { ...state, isErr: true, isSuc: false, isL: false };

        default: return state;
    }
}