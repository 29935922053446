import React,{Suspense} from 'react'
import './App.css';
import Users from './routes/users';
import{BrowserRouter as Router,} from 'react-router-dom'
import './components/fontawesome';

function App() {
  return (
    <Suspense fallback={<div>looding</div>}>
    <Router>
      <Users/>
    </Router>
    </Suspense>
  );
}

export default App;
